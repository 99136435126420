import React from 'react'
import {createRoot} from 'react-dom/client'
import CommentForm from "./component/CommentForm"

(function ($: JQueryStatic) {
    $.fn.extend({
        "commentForm": function (this: JQuery, options: Record<string, any>) {
            this.each(((i, elm) => {
                const $elm = $(elm)
                const params = collectParams($elm, options)
                createRoot(elm).render(React.createElement(CommentForm, {
                    ...params,
                    securityCheck: {
                        // ダミー (HTML で設定せずコンポーネントで固定実装なので)
                        preCheckSms: false,
                        checkSms: true,
                        targetPage: $elm.data('page'),
                        interruptType: $elm.data('interrupt-type'),
                        unfreezeUrl: $elm.data('unfreeze-url'),
                    }
                }))
            }))
        }
    })

    function collectParams(elm: JQuery, options: Record<string, any>) {
        return $.extend({
            formActionPath: elm.data('form-action-path'),
            page: elm.data('page'),
            commentNo: elm.data('comment-no'),
            noDate: elm.data('no-date'),
            noName: elm.data('no-name'),
            above: elm.data('above'),
            capacityWarning: elm.data('capacity-warning'),
        }, options)
    }
})(jQuery)
