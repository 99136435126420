import {EditAssistant} from '@shared/editAssistant'
import {OperableTextFormComponentType} from '@shared/util/form-text-ops'

import withSecurityCheck, {CheckAndActionProps} from "@shared/security/SecurityCheck"
import SecurityCheckForm from "@shared/security/SecurityCheckForm"
import {useRef, useState} from 'react'
import {createTextFormStore} from './_text-form-common'
import ControlledTextarea from './ControlledTextarea'

interface ArticleFormProps {
    formActionPath: string
    articleNo: number
    digest: string
}

const ArticleForm = withSecurityCheck(_ArticleForm)
export default ArticleForm

function _ArticleForm({
    formActionPath, articleNo,
    digest, onAction, isCheckComplete,
}: ArticleFormProps & CheckAndActionProps) {
    const ARTICLE_NAME_COLS = 24
    const ARTICLE_SUBJECT_COLS = 60
    const ARTICLE_ROWS = 5
    const ARTICLE_COLS = 70

    const editorRef = useRef<OperableTextFormComponentType>()

    const [name, setName] = useState("")
    const [subject, setSubject] = useState("")

    const useStore = createTextFormStore()
    const {editData} = useStore()

    return (
    <SecurityCheckForm onSubmit={(event) => {
        onAction(event, name + "\n\n" + subject + "\n\n" + editData)
    }} isCheckComplete={isCheckComplete} action={formActionPath} method="post">
        <div className="article_form">
            <input type="hidden" name="article_no" value={articleNo} />
            <input type="hidden" name="digest" value={digest} />
            <label htmlFor={'_p_article_name_' + articleNo} >お名前</label>
            <input type="text" name="name" id={'_p_article_name_' + articleNo} size={ARTICLE_NAME_COLS}
                   value={name} onChange={(e) => setName(e.currentTarget.value)}
            /><br/>
            <label htmlFor={'_p_article_subject_' + articleNo} >題名: </label>
            <input type="text" name="subject" id={'_p_article_subject_' + articleNo} size={ARTICLE_SUBJECT_COLS}
                   value={subject} onChange={(e) => setSubject(e.currentTarget.value)}
            /><br/>
            <ControlledTextarea useStore={useStore} name="msg" rows={ARTICLE_ROWS} cols={ARTICLE_COLS} ref={editorRef} />
            <br />
            <input type="submit" name="article" value="記事の投稿"/>
            <EditAssistant inlineOnly={false} editorRef={editorRef}/>
        </div>
    </SecurityCheckForm>
    )
}
