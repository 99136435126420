import accordion from "./accordion.yml"
import aname from "./aname.yml"
import areaedit from "./areaedit.yml"
import article from "./article.yml"
import attach from "./attach.yml"
import attachref from "./attachref.yml"
import back from "./back.yml"
import br from "./br.yml"
import calc from "./calc.yml"
import calendar2 from "./calendar2.yml"
import calendar_viewer from "./calendar_viewer.yml"
import clear from "./clear.yml"
import code from "./code.yml"
import color from "./color.yml"
import comment from "./comment.yml"
import contents from "./contents.yml"
import counter from "./counter.yml"
import cssbox from "./cssbox.yml"
import embed from "./embed.yml"
import fa from "./fa.yml"
import flexSet from "./flex_container, flex_box.yml"
import fold from "./fold.yml"
import google_form from "./google_form.yml"
import google_map from "./google_map.yml"
import google_spreadsheet from "./google_spreadsheet.yml"
import hr from "./hr.yml"
import include from "./include.yml"
import insert from "./insert.yml"
import lastmod from "./lastmod.yml"
import lookup from "./lookup.yml"
import ls from "./ls.yml"
import ls2 from "./ls2.yml"
import memo from "./memo.yml"
import moved from "./moved.yml"
import navi from "./navi.yml"
import newPlugin from "./new.yml"
import nicovideo from "./nicovideo.yml"
import nobr from "./nobr.yml"
import ntbr from "./ntbr.yml"
import online from "./online.yml"
import pcomment from "./pcomment.yml"
import popout from "./popout.yml"
import popular from "./popular.yml"
import pre from "./pre.yml"
import qrcode from "./qrcode.yml"
import recent from "./recent.yml"
import ref from "./ref.yml"
import responsiveSet from "./responsive_layout_container, responsive_layout_item.yml"
import ruby from "./ruby.yml"
import search from "./search.yml"
import setlinebreak from "./setlinebreak.yml"
import short_url from "./short_url.yml"
import showrss from "./showrss.yml"
import size from "./size.yml"
import soundcloud from "./soundcloud.yml"
import tablescroll from "./tablescroll.yml"
import tablesort from "./tablesort.yml"
import tategaki from "./tategaki.yml"
import twitter_timeline from "./twitter_timeline.yml"
import twitter_tweet from "./twitter_tweet.yml"
import vote from "./vote.yml"
import youtube from "./youtube.yml"
import zcomment from "./zcomment.yml"
import zrecent from "./zrecent.yml"

type PluginType = 'inline' | 'block' | 'command' | 'semiCommand'
export type PluginTag = 'UI' | 'edit' | 'file' | 'link' | 'form' | 'embed' | 'others'

export interface PluginShortcutItem {
    name: string
    shortDescription: string
    displayType: PluginType[]
    format: string
    inputFormat: string
    summary: string
    argsDescription: string
    note: string
    tag: PluginTag
    inputType: 'inline' | 'block'
    bodyText: string | null
}

export const pluginShortcutItems: PluginShortcutItem[] = [
    accordion,
    aname,
    areaedit,
    article,
    attach,
    attachref,
    back,
    br,
    calc,
    calendar2,
    calendar_viewer,
    clear,
    code,
    color,
    comment,
    contents,
    counter,
    cssbox,
    embed,
    fa,
    flexSet,
    fold,
    google_form,
    google_map,
    google_spreadsheet,
    hr,
    include,
    insert,
    lastmod,
    lookup,
    ls,
    ls2,
    memo,
    moved,
    navi,
    newPlugin,
    nicovideo,
    nobr,
    ntbr,
    online,
    pcomment,
    popout,
    popular,
    pre,
    qrcode,
    recent,
    ref,
    responsiveSet,
    ruby,
    search,
    setlinebreak,
    short_url,
    showrss,
    size,
    soundcloud,
    tablescroll,
    tablesort,
    tategaki,
    twitter_timeline,
    twitter_tweet,
    vote,
    youtube,
    zcomment,
    zrecent,
]

export const newPluginNames = [
    // 2022年5月の時点で最新機能とされていたもの
    // 'flex_container, flex_box',
    // 'moved',
    // 'ntbr',
    // 'popout',
    // 'responsive_layout_container, responsive_layout_item',

    // 2024年2月に公開された機能
    'tategaki',

    // 2024年9月に追加
    'cssbox',       // 実は2023年5月ごろリリース済み
    'tablescroll',  // 実は2023年5月ごろリリース済み
    'fa',           // 実は2023年6月ごろにはリリース済み

    // 将来用
    // 'icon',         // 2024年7月限定リリース
    // 'fcache',       // 2024年7月にできていていまだ非公開
]
