import {RefObject} from 'react'

export type SelectionRangeElement = { start: number, end: number }

export interface OperableTextFormComponentType {
    getEditData(): string
    setEditData(data: ((data: string) => string) | string): void
    getSelections(): SelectionRangeElement[]
    setSelections(selections: SelectionRangeElement[]): void
    focus(): void
}

export function textOperationFunctions(refObject: RefObject<OperableTextFormComponentType>) {
    return {
        selectionReplace(replace: (s: string) => string, selections: SelectionRangeElement[] = null) {
            const target = refObject.current
            if (selections === null) {
                selections = target.getSelections()
            }
            const newSelections = []
            let addition = 0

            for (let i = 0; i < selections.length; i++) {
                const selection = selections[i]
                selection.start += addition
                selection.end += addition

                target.setEditData(data => {
                    // 逆向きもありうるので順方向にそろえる
                    const selectionStart = Math.min(selection.start, selection.end)
                    const selectionEnd = Math.max(selection.start, selection.end)

                    const strBefore = data.substring(0, selectionStart)
                    const strAfter = data.substring(selectionEnd, data.length)

                    const currentSelection = data.substring(selectionStart, selectionEnd)

                    const str = replace(currentSelection)

                    const newSelectionEnd = selectionStart + str.length
                    newSelections.push({start: selectionStart, end: newSelectionEnd})

                    // 次の selection 位置を追加した文字数分だけずらす
                    addition += str.length - currentSelection.length

                    return strBefore + str + strAfter
                })
            }

            target.setSelections(newSelections)
        },
        insertAtCursor(str: string, eraseSelection: boolean = false) {
            const target = refObject.current
            const selections = target.getSelections()
            const newSelections = []
            let addition = 0

            for (let i = 0; i < selections.length; i++) {
                const selection = selections[i]
                selection.start += addition
                selection.end += addition

                target.setEditData(data => {
                    // 逆向きもありうるので順方向にそろえる
                    const selectionStart = Math.min(selection.start, selection.end)
                    const selectionEnd = Math.max(selection.start, selection.end)

                    const strBefore = data.substring(0, selectionStart)
                    const strAfter = data.substring(selectionEnd, data.length)

                    const currentSelection = data.substring(selectionStart, selectionEnd)

                    const newSelectionEnd = selectionEnd + str.length
                    newSelections.push({start: selectionStart, end: newSelectionEnd})

                    // 次の selection 位置を追加した文字数分だけずらす
                    addition += str.length

                    return eraseSelection
                        ? strBefore + str + strAfter
                        : strBefore + currentSelection + str + strAfter
                })
            }

            target.setSelections(newSelections)
        },

        surroundWith(beforeText: string, afterText: string, defaultText?: string) {
            const target = refObject.current
            const selections = target.getSelections()
            const newSelections = []
            let addition = 0

            for (let i = 0; i < selections.length; i++) {
                const selection = selections[i]
                selection.start += addition
                selection.end += addition

                target.setEditData(data => {
                    // 逆向きもありうるので順方向にそろえる
                    const selectionStart = Math.min(selection.start, selection.end)
                    const selectionEnd = Math.max(selection.start, selection.end)

                    const strBefore = data.substring(0, selectionStart)
                    const strAfter = data.substring(selectionEnd, data.length)

                    const currentSelection = data.substring(selectionStart, selectionEnd)
                    const addDefaultText = currentSelection.length === 0
                        && defaultText !== undefined
                        && defaultText.length > 0
                    const selectionText = addDefaultText ? defaultText : currentSelection
                    if (selectionText.length === 0) {
                        // 文字が何も選択されてなければ、真ん中にカーソルを持ってくる
                        const newSelectionStart = selectionStart + beforeText.length
                        newSelections.push({start: newSelectionStart, end: newSelectionStart})
                    } else {
                        const newSelectionEnd = selectionEnd + beforeText.length + afterText.length
                            + (addDefaultText ? selectionText.length : 0)
                        newSelections.push({start: selectionStart, end: newSelectionEnd})
                    }

                    // 次の selection 位置を追加した文字数分だけずらす
                    addition += beforeText.length + afterText.length
                    if (addDefaultText) {
                        addition += selectionText.length
                    }

                    return strBefore + beforeText + selectionText + afterText + strAfter
                })
            }

            target.setSelections(newSelections)
        },

        insertAtLineHead(str: string) {
            const target = refObject.current
            const selection = target.getSelections()[0]
            const newSelections = []

            target.setEditData(data => {
                // 逆向きもありうるので順方向にそろえる
                let selectionStart = Math.min(selection.start, selection.end)
                const selectionEnd = Math.max(selection.start, selection.end)

                // 選択範囲の始端を行頭まで拡張
                while (selectionStart > 0 && !data.charAt(selectionStart - 1).match(/\n/)) {
                    selectionStart--
                }

                const strBefore = data.substring(0, selectionStart)
                const strAfter = data.substring(selectionEnd, data.length)

                const processedStr = data
                .substring(selectionStart, selectionEnd)
                .split('\n')
                .map(row => str + row)
                .join('\n')

                const newSelectionStart = strBefore.length
                let newSelectionEnd = newSelectionStart + processedStr.length
                const newData = strBefore + processedStr + strAfter

                // 選択範囲の終端を行末まで拡張
                while (newData.length > newSelectionEnd && !newData.charAt(newSelectionEnd).match(/\n/)) {
                    newSelectionEnd++
                }

                newSelections.push({start: newSelectionStart, end: newSelectionEnd})

                return newData
            })

            target.setSelections(newSelections)
        },

        surroundWithLineBreaking(beforeText: string, afterText: string, defaultText?: string) {
            const target = refObject.current
            const selections = target.getSelections()
            const newSelections = []
            let addition = 0

            for (let i = 0; i < selections.length; i++) {
                const selection = selections[i]
                selection.start += addition
                selection.end += addition

                target.setEditData(data => {
                    // 逆向きもありうるので順方向にそろえる
                    const selectionStart = Math.min(selection.start, selection.end)
                    const selectionEnd = Math.max(selection.start, selection.end)

                    const strBefore = data.substring(0, selectionStart)
                    const strAfter = data.substring(selectionEnd, data.length)
                    const currentSelection = data.substring(selectionStart, selectionEnd)

                    const breakBefore = !(strBefore.endsWith("\n") || currentSelection.startsWith("\n"))
                    const breakAfter = !(currentSelection.endsWith("\n") || strAfter.startsWith("\n"))

                    const addDefaultText = currentSelection.length === 0
                        && defaultText !== undefined
                        && defaultText.length > 0
                    const selectionText = addDefaultText ? defaultText : currentSelection
                    if (selectionText.length === 0) {
                        // 文字が何も選択されてなければ、真ん中にカーソルを持ってくる
                        const newSelectionStart = selectionStart + beforeText.length
                        newSelections.push({start: newSelectionStart, end: newSelectionStart})
                    } else {
                        const newSelectionEnd = selectionEnd + beforeText.length + afterText.length
                            + (addDefaultText ? selectionText.length : 0)
                        newSelections.push({start: selectionStart, end: newSelectionEnd})
                    }

                    // 次の selection 位置を追加した文字数分だけずらす
                    addition += beforeText.length + afterText.length
                    if (addDefaultText) {
                        addition += selectionText.length
                    }

                    return strBefore + (breakBefore ? "\n" : "") + beforeText +
                        selectionText +
                        afterText + (breakAfter ? "\n" : "") + strAfter
                })
            }

            target.setSelections(newSelections)
        },

        insertQuote() {
            const target = refObject.current
            const selection = target.getSelections()[0]
            const newSelections = []

            target.setEditData(data => {
                // 逆向きもありうるので順方向にそろえる
                let selectionStart = Math.min(selection.start, selection.end)
                const selectionEnd = Math.max(selection.start, selection.end)

                // 選択範囲の始端を行頭まで拡張
                while (selectionStart > 0 && !data.charAt(selectionStart - 1).match(/\n/)) {
                    selectionStart--
                }

                const selectedRows = data
                .substring(selectionStart, selectionEnd)
                .split('\n')

                let paragraphTop = true

                const processedRows = []

                for(let i = 0; i < selectedRows.length; i++) {
                    let row = selectedRows[i]

                    if (row.trim().length === 0) {
                        row = '>' + row
                        paragraphTop = true
                    } else if (paragraphTop) {
                        row = '>' + row
                        paragraphTop = false
                    }

                    processedRows.push(row)
                }

                const strBefore = data.substring(0, selectionStart)
                const strAfter = data.substring(selectionEnd, data.length)

                const processedStr = processedRows.join('\n')

                const newSelectionStart = strBefore.length
                let newSelectionEnd = newSelectionStart + processedStr.length
                const length = strBefore.length + processedStr.length + strAfter.length
                // 選択範囲の終端を行末まで拡張
                while (length > newSelectionEnd && !data.charAt(selectionEnd).match(/\n/)) {
                    newSelectionEnd++
                }

                newSelections.push({start: newSelectionStart, end: newSelectionEnd})

                return strBefore + processedStr + strAfter
            })

            target.setSelections(newSelections)
        },
        getSelectedText() {
            const target = refObject.current
            if (!target) {
                return ''
            }

            const selection = target.getSelections()[0]
            // 逆向きもありうるので順方向にそろえる
            const selectionStart = Math.min(selection.start, selection.end)
            const selectionEnd = Math.max(selection.start, selection.end)

            return target.getEditData().substring(selectionStart, selectionEnd)
        }
    }
}
