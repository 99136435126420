import {OperableTextFormComponentType} from '@shared/util/form-text-ops'
import {ForwardedRef, forwardRef, Ref, useRef} from 'react'
import {TextFormStoreAwareProps, useOperableTextForm} from './_text-form-common'

const ControlledTextarea = forwardRef((
    props: TextFormStoreAwareProps,
    ref: ForwardedRef<OperableTextFormComponentType>,
) => {
    const {useStore, ...propsWithoutUseStore} = props
    const {
        editData, setEditData,
    } = useStore()
    const editorRef = useRef<HTMLInputElement | HTMLTextAreaElement>()

    useOperableTextForm(ref, editorRef, useStore)

    return <textarea value={editData} onChange={e => setEditData(e.currentTarget.value)}
                      ref={editorRef as Ref<HTMLTextAreaElement>} {...propsWithoutUseStore}/>
})

export default ControlledTextarea
