import useLocalStateUseStore from '@shared/util/zustand-hook'
import {StoreApi} from 'zustand'
import {ColorModal, ColorModalState, createColorModalState} from './ColorModal'
import {CommonModalState, createCommonModalState} from './common'
import {createEmbedModalState, EmbedModal, EmbedModalState} from './EmbedModal'
import {createFaceImageButtonState, FaceImageButton, FaceImageButtonState} from './FaceImageButton'
import {createFontSizeModalState, FontSizeModal, FontSizeModalState} from './FontSizeModal'
import {createLinkModalState, LinkModal, LinkModalState} from './LinkModal'
import {createNtbrModalState, NtbrModal, NtbrModalState} from './NtbrModal'
import {createPluginModalState, PluginModal, PluginModalState} from './PluginModal'
import {createTableModalState, TableModal, TableModalState} from './TableModal'
import {createTwitterModalState, TwitterModal, TwitterModalState} from './TwitterModal'

export {EditAssistant} from './EditAssistant'
export {
    ColorModal,
    EmbedModal,
    FaceImageButton,
    FontSizeModal,
    LinkModal,
    NtbrModal,
    PluginModal,
    TableModal,
    TwitterModal,
}

type EditAssistantModalState = ColorModalState
                                & FontSizeModalState
                                & NtbrModalState
                                & TableModalState
                                & EmbedModalState
                                & TwitterModalState
                                & LinkModalState
                                & FaceImageButtonState
                                & PluginModalState
                                & CommonModalState

function EditAssistantModalStoreCreator(
    set: StoreApi<EditAssistantModalState>['setState'],
    get: StoreApi<EditAssistantModalState>['getState']
): EditAssistantModalState {
    return {
        ...createColorModalState(set, get),
        ...createFontSizeModalState(set, get),
        ...createNtbrModalState(set, get),
        ...createTableModalState(set, get),
        ...createEmbedModalState(set, get),
        ...createTwitterModalState(set, get),
        ...createLinkModalState(set, get),
        ...createFaceImageButtonState(set, get),
        ...createPluginModalState(set, get),
        ...createCommonModalState(set, get),
    }
}

export function useEditAssistantLocalStateUseStore() {
    return useLocalStateUseStore<EditAssistantModalState>(EditAssistantModalStoreCreator)
}

