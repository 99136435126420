import {OperableTextFormComponentType} from '@shared/util/form-text-ops'
import {ForwardedRef, forwardRef, SyntheticEvent, useRef, useState} from 'react'
import {TextFormStoreAwareProps, useOperableTextForm} from './_text-form-common'

const MIN_TEXTAREA_HEIGHT = 30
const MAX_TEXTAREA_HEIGHT = MIN_TEXTAREA_HEIGHT * 4

const LINEBREAK_REPLACEMENT = ''

const AutoResizeTextarea = forwardRef((
    props: TextFormStoreAwareProps,
    ref: ForwardedRef<OperableTextFormComponentType>,
) => {
    const {useStore, ...propsWithoutUseStore} = props
    const {editData, setEditData} = useStore()
    const [resized, setResized] = useState(false)

    const editorRef = useRef<HTMLTextAreaElement>()

    useOperableTextForm(ref, editorRef, useStore)

    const onChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
        setEditData(e.currentTarget.value.replace(/\n/g, LINEBREAK_REPLACEMENT))
        if (e.currentTarget.value.length > 0) {
            setResized(true)
        }
    }

    const onBlur = () => {
        if (editData.length === 0) {
            setResized(false)
        }
    }

    return <textarea className="auto-resize-textarea" value={editData} onChange={onChange} onBlur={onBlur}
                     ref={editorRef} style={{
                            height: (resized ? MAX_TEXTAREA_HEIGHT : MIN_TEXTAREA_HEIGHT) + 'px',
                            transition: 'height 0.5s'
                        }}
                     {...propsWithoutUseStore}/>
})

export default AutoResizeTextarea
