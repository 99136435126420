import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import StyledReactModal from '@shared/util/StyledReactModal'
import {RefObject, useEffect, useRef} from 'react'
import ReactModal from 'react-modal'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'
import {newPluginNames, PluginShortcutItem, pluginShortcutItems} from './pluginShortcutItems'

export interface PluginModalState {
    isPluginModalOpen: boolean
    query: string
    selectedItem: PluginShortcutItem | null
    selectedItems: PluginShortcutItem[]
    openPluginModal(): void
    closePluginModal(): void
    setQuery(query: string): void
    setSelectedItem(item: PluginShortcutItem | null): void
    setSelectedItems(items: PluginShortcutItem[]): void
    backToList(): void
    clearPluginModal(): void
}


export function createPluginModalState(
    set: StoreApi<PluginModalState>['setState'],
    get: StoreApi<PluginModalState>['getState']
): PluginModalState {
    return {
        isPluginModalOpen: false,
        query: '',
        selectedItem: null,
        selectedItems: pluginShortcutItems,
        openPluginModal() {
            set({isPluginModalOpen: true})
        },
        closePluginModal() {
            set({isPluginModalOpen: false})
            get().clearPluginModal()
        },
        setQuery(query: string) {
            set({query: query})
        },
        setSelectedItem(item: PluginShortcutItem | null) {
            set({selectedItem: item})
        },
        setSelectedItems(items: PluginShortcutItem[]) {
            set({
                selectedItem: null,
                selectedItems: items,
            })
        },
        backToList() {
            set({
                selectedItem: null,
            })
        },
        clearPluginModal() {
            set({
                query: '',
                selectedItem: null,
                selectedItems: pluginShortcutItems,
            })
        }
    }
}

export function PluginModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<PluginModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isPluginModalOpen, closePluginModal,
        selectedItem, setSelectedItem,
        selectedItems, setSelectedItems,
        backToList
    } = useStore()
    const categories = {
        UI: 'レイアウト・UI',
        edit: '編集',
        file: 'ファイル・画像',
        link: 'リンク',
        form: 'フォーム',
        embed: '埋め込み',
        others: 'その他',
    }

    const selectItemsByCategory = (category: string) => {
        setSelectedItems(pluginShortcutItems.filter(item => item.tag.includes(category)))
    }

    const searchPlugin = e => {
        backToList()
        const searchWord = e.currentTarget.value
        setSelectedItems(pluginShortcutItems.filter(item => {
            return item.name.indexOf(searchWord) !== -1
                || item.shortDescription.indexOf(searchWord) !== -1
                || item.summary.indexOf(searchWord) !== -1
                || item.argsDescription.indexOf(searchWord) !== -1
                || item.note.indexOf(searchWord) !== -1
        }))
    }

    const customModalStyle: ReactModal.Styles = {
        content: {
            verticalAlign: 'middle',
            position: 'absolute',
            inset: '50% auto auto 50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 999999,
            maxWidth: '800px',
            boxSizing: 'border-box',
            width: '90%',
            background: '#fff',
            padding: 0,
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0 0 10px #000',
            textAlign: 'left',
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            zIndex: 999999,
        }
    }

    // メインエリアの内容が変わったらスクロール位置をトップに戻す
    const mainAreaRef = useRef<HTMLDivElement>()
    useEffect(() => {
        if (mainAreaRef.current) {
            mainAreaRef.current.scrollTop = 0
        }
    }, [selectedItems, selectedItem])

    return <StyledReactModal className="edit-assistant-modal plugin-modal" isOpen={isPluginModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true}
                             onRequestClose={() => closePluginModal()} style={customModalStyle}>
        <div className="header">
            <h3 className="modal-title">プラグイン</h3>
            <div className="search-box">
                <FontAwesomeIcon icon={['fas', 'search']} color="#888"/>
                <input type="search" onChange={searchPlugin}/>
            </div>
        </div>

        <div className="modal-body">
            <div className="categories">
                <ul>
                    {
                        Object.keys(categories).map((e, i) => <li key={i} onClick={() => selectItemsByCategory(e)}>{categories[e]}</li>)
                    }
                </ul>
            </div>
            <div className="main-area" ref={mainAreaRef}>
                {selectedItem !== null
                    ? <PluginShortcutDescription item={selectedItem} useStore={useStore} editorRef={editorRef}/>
                    : <ul>
                        {
                            selectedItems.map((item, i) => {
                                return <li key={i} onClick={() => setSelectedItem(item)}>
                                    <span className="plugin-name">{item.name}</span>
                                    {' - ' + item.shortDescription}
                                    {newPluginNames.includes(item.name) &&
                                        <span className="new1">New!</span>
                                    }
                                </li>
                            })
                        }
                    </ul>
                }
            </div>
        </div>
    </StyledReactModal>
}


function PluginShortcutDescription<T extends PluginModalState & CommonModalState>({item, useStore, editorRef}: {
    item: PluginShortcutItem
    useStore: UseBoundStore<StoreApi<PluginModalState & CommonModalState>>,
    editorRef: RefObject<OperableTextFormComponentType>,
}) {
    const pluginTypeLabelMap = {
        inline: 'インライン型プラグイン',
        block: 'ブロック型プラグイン',
        command: 'コマンド',
        semiCommand: '(コマンド)',
    }

    const {
        backToList,
        closePluginModal, setFocus
    } = useStore()
    const {
        insertAtCursor,
        insertAtLineHead,
        surroundWith,
        surroundWithLineBreaking
    } = textOperationFunctions(editorRef)

    const handleInput = () => {
        const pluginStr = item.inputFormat
        if (item.bodyText === null) {
            if (item.inputType === 'inline') {
                insertAtCursor(pluginStr)
            } else {
                insertAtLineHead(pluginStr + '\n')
            }
        } else {
            if (item.inputType === 'inline') {
                surroundWith(pluginStr + '{', '};', item.bodyText)
            } else {
                surroundWithLineBreaking(pluginStr + '{{\n', '\n}}', item.bodyText)
            }
        }

        closePluginModal()
        setFocus(true)
    }

    return <>
        <dl className="plugin-description">
            <span className="back" onClick={() => backToList()}><FontAwesomeIcon icon={['fas', 'caret-left']}/>戻る</span>
            <h3>{item.name}</h3>
            <dt>種別</dt>
            <dd>
                {
                    item.displayType.map(tag => <p key={tag}>{pluginTypeLabelMap[tag]}</p>)
                }
            </dd>
            <dt>書式</dt>
            <dd>
                <pre>{item.format}</pre>
            </dd>
            <button className="insert-button" type="button" onClick={handleInput}>挿入</button>
            {item.summary.length > 0 && <>
                <dt>概要</dt>
                <dd className="summary">{item.summary}</dd>
            </>
            }
            {item.argsDescription.length > 0 && <>
                <dt>引数</dt>
                <dd className="args-description">{item.argsDescription}</dd>
            </>
            }
            {item.note.length > 0 && <>
                <dt>備考</dt>
                <dd className="note">{item.note}</dd>
            </>
            }
        </dl>
    </>
}
