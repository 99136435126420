import {RefObject} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import StyledReactModal from '@shared/util/StyledReactModal'
import {TwitterPicker} from 'react-color'
import {COLORS_MAP, CommonModalState} from './common'

export interface NtbrModalState {
    isNtbrModalOpen: boolean
    maskColor: string
    openNtbrModal(): void
    closeNtbrModal(): void
    setMaskColor(color: string): void
    clearNtbrModal(): void
}

export function createNtbrModalState(
    set: StoreApi<NtbrModalState>['setState'],
    get: StoreApi<NtbrModalState>['getState']
): NtbrModalState {
    return {
        isNtbrModalOpen: false,
        maskColor: '',
        openNtbrModal() {
            set({isNtbrModalOpen: true})
        },
        closeNtbrModal() {
            set({isNtbrModalOpen: false})
            get().clearNtbrModal()
        },
        setMaskColor(color) {
            set({maskColor: color})
        },
        clearNtbrModal() {
            set({maskColor: ''})
        }
    }
}

export function NtbrModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<NtbrModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isNtbrModalOpen, maskColor, setMaskColor,
        closeNtbrModal, setFocus,
    } = useStore()

    const {surroundWith} = textOperationFunctions(editorRef)

    const styles = {
        default: {
            hash: {
                display: 'none',
            },
            card: {
                marginRight: 'auto',
                marginLeft: 'auto',
            }
        },
    }

    const settingColor = () => {
        const argChar = COLORS_MAP.hasOwnProperty(maskColor) ? COLORS_MAP[maskColor] : maskColor
        const beforeText = '&ntbr(' + argChar + '){'
        const afterText = '};'
        surroundWith(beforeText, afterText)
        closeNtbrModal()
        setFocus(true)
    }

    return <StyledReactModal className="edit-assistant-modal ntbr-modal" isOpen={isNtbrModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true} onRequestClose={() => closeNtbrModal()}>
        <h3 className="modal-title">ntbrプラグイン</h3>
        <TwitterPicker width="50%" color={maskColor} colors={Object.keys(COLORS_MAP)} styles={styles}
                       onChangeComplete={(color) => setMaskColor(color.hex)}/>
        <div className="buttons">
            <button className="ok-button" type="button" onClick={settingColor}>OK</button>
            <button type="button" onClick={() => closeNtbrModal()}>キャンセル</button>
        </div>
    </StyledReactModal>
}
