import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EditAssistant} from '@shared/editAssistant'

import withSecurityCheck, {CheckAndActionProps} from "@shared/security/SecurityCheck"
import SecurityCheckForm from "@shared/security/SecurityCheckForm"
import {OperableTextFormComponentType} from '@shared/util/form-text-ops'
import classNames from 'classnames'
import {ReactNode, useEffect, useRef, useState} from 'react'
import {createTextFormStore} from './_text-form-common'
import AutoResizeTextarea from './AutoResizeTextarea'

export interface PCommentFormProps {
    formActionPath: string
    commentPageUrl: string
    commentPage: string
    commentPageExists: boolean
    linkedPageName: string
    noDate: '1' | '0'
    noName: boolean
    dir: 1 | 0
    count: number
    reply: boolean
    capacityWarning: string
    commentsElement: HTMLElement | undefined
}

const PCommentForm = withSecurityCheck(_PCommentForm)
export default PCommentForm

function _PCommentForm({
    formActionPath, commentPageUrl, commentPage, commentPageExists,
    linkedPageName, noDate, noName, dir, count, reply, capacityWarning,
    commentsElement,
    onAction, isCheckComplete,
}: PCommentFormProps & CheckAndActionProps) {
    const PCOMMENT_SIZE_NAME = 12
    const PCOMMENT_SIZE_MSG = 58

    const editorRef = useRef<OperableTextFormComponentType>()

    const [name, setName] = useState("")

    const useStore = createTextFormStore()
    const {editData} = useStore()

    const [isEditAssistantShown, setIsEditAssistantShown] = useState(false)

    const link = <p>
        {commentPageExists ? `最新の${count}件を表示しています。` : 'コメントはありません。'}
        <PageLink url={commentPageUrl} pageExists={commentPageExists}
                  title={commentPage}>
            {commentPageExists ? 'コメントページを参照' : linkedPageName}
        </PageLink>
    </p>

    const commentListRef = useRef<HTMLDivElement>()
    useEffect(() => {
        if (!commentsElement || commentsElement.parentElement === commentListRef.current) {
            return
        }
        // console.log("render comments")
        commentListRef.current.appendChild(commentsElement)
    })
    // const [experimentState, setExperimentState] = useState(false)

    const handleOnKeyDown = (e: React.KeyboardEvent) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            e.preventDefault()
        }
    }

    return <>
        <div>
            {/*{ <button onClick={() => {setExperimentState(!experimentState)}}>ぽちぽち</button> }*/}
            {dir === 1 && link}
            <SecurityCheckForm onSubmit={(event) => {
                onAction(event, name + "\n\n" + editData)
            }} isCheckComplete={isCheckComplete} action={formActionPath} method="post">
                {dir === 1 && <div className="pcomment-comments-container" ref={commentListRef}/>}
                <div className={classNames('pcommentform', {
                    'no-edit-assistant': !isEditAssistantShown,
                    'has-capacity-warning': capacityWarning !== "",
                })}>
                    <input type="hidden" name="nodate" value={noDate}/>
                    <input type="hidden" name="dir" value={dir}/>
                    <input type="hidden" name="count" value={count}/>
                    {reply &&
                        // Property 'defaultChecked' does not exist on type 'HTMLAttributes<HTMLInputElement>'.
                        // ↑ 何故かエラーに。
                        // @ts-ignore
                        <input type="radio" name="reply" value="0" tabIndex={0} defaultChecked={true}/>
                    }
                    {!noName &&
                        <input type="text" name="name" size={PCOMMENT_SIZE_NAME} placeholder="お名前"
                               value={name} onChange={(e) => setName(e.currentTarget.value)}
                               onKeyDown={handleOnKeyDown}/>
                    }
                    <AutoResizeTextarea useStore={useStore} name="msg" autoComplete="off" placeholder="コメント"
                                        cols={noName ? PCOMMENT_SIZE_NAME + PCOMMENT_SIZE_MSG : PCOMMENT_SIZE_MSG}
                                        ref={editorRef} onKeyDown={handleOnKeyDown}
                                        onFocus={() => setIsEditAssistantShown(true)}/>
                    <input type="submit" name="pcomment" value="挿入"/>

                    <div className={classNames('edit-assistant-area', {
                        shown: isEditAssistantShown,
                        hide: !isEditAssistantShown,
                    })}>
                        {capacityWarning !== "" && <div className="comment-capacity-warning">{capacityWarning}</div>}
                        <div className="controls">
                            <EditAssistant inlineOnly={true} editorRef={editorRef}/>
                            <FontAwesomeIcon className="hide-edit-assistant" icon={['fas', 'angle-up']} size="lg"
                                             onClick={() => setIsEditAssistantShown(false)}/>
                        </div>
                    </div>
                </div>
                {dir === 0 && <div className="pcomment-comments-container" ref={commentListRef}/>}
            </SecurityCheckForm>
            {dir === 0 && link}
        </div>
    </>
}

function PageLink({url, pageExists, children, ...props}: {
    url: string
    pageExists: boolean
    children: ReactNode
    // noinspection JSUnusedLocalSymbols
    [prop: string]: any
}) {
    return <>
        {pageExists
            ? <a href={url} className="rel-wiki-page" {...props}>{children}</a>
            : <span className="noexists">{children}
                <a href={url} {...props}>?</a>
            </span>}
    </>
}
