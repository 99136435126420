import {OperableTextFormComponentType, SelectionRangeElement} from '@shared/util/form-text-ops'
import useLocalStateUseStore from '@shared/util/zustand-hook'
import {ForwardedRef, RefObject, useEffect, useImperativeHandle} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'

interface TextFormStoreState {
    editData: string
    selections: SelectionRangeElement[]
    setEditData(editData: string | ((str: string) => string)): void
    setSelections(selections: SelectionRangeElement[]): void
}

const textFormStoreCreator = (set, get): TextFormStoreState => ({
    editData: "",
    selections: [],
    setEditData(editData) {
        if (typeof editData === 'function') {
            editData = editData(get().editData)
        }
        set({editData: editData})
    },
    setSelections(selections) {
        set({selections: selections})
    },
})

export const createTextFormStore = () => {
    return useLocalStateUseStore(textFormStoreCreator)
}

export interface TextFormStoreAwareProps {
    useStore: UseBoundStore<StoreApi<TextFormStoreState>>
    [others: string]: any
}

export function useOperableTextForm(
    imperativeHandleRef: ForwardedRef<OperableTextFormComponentType>,
    editorRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
    useStore: UseBoundStore<StoreApi<TextFormStoreState>>
) {
    const {editData, setEditData, selections, setSelections,} = useStore()

    // textarea はここで setSelectionRange してやらないとうまく行かない
    useEffect(() => {
        if (selections.length === 0) {
            return
        }

        const textarea = editorRef.current
        textarea.setSelectionRange(selections[0].start, selections[0].end)
    }, [selections])

    useImperativeHandle(imperativeHandleRef, (): OperableTextFormComponentType => ({
        getEditData(): string {
            return editData
        },
        setEditData(data) {
            setEditData(data)
        },
        getSelections() {
            const textarea = editorRef.current
            return [{
                start: textarea.selectionStart,
                end: textarea.selectionEnd,
            }]
        },
        setSelections(selections) {
            setSelections(selections)
            this.focus()
        },
        focus() {
            const textarea = editorRef.current
            textarea.focus()
        },
    }))
}
