import {RefObject} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'
import StyledReactModal from '@shared/util/StyledReactModal'

export interface FontSizeModalState {
    isFontSizeModalOpen: boolean
    fontSize: number | null
    openFontSizeModal(): void
    closeFontSizeModal(): void
    setFontSize(size: number): void
    clearFontSize(): void
}

export function createFontSizeModalState(
    set: StoreApi<FontSizeModalState>['setState'],
    get: StoreApi<FontSizeModalState>['getState']
): FontSizeModalState {
    return {
        isFontSizeModalOpen: false,
        fontSize: null,
        openFontSizeModal() {
            set({isFontSizeModalOpen: true})
        },
        closeFontSizeModal() {
            set({isFontSizeModalOpen: false})
            get().clearFontSize()
        },
        setFontSize(size) {
            set({fontSize: size})
        },
        clearFontSize() {
            set({fontSize: null})
        },
    }
}

export function FontSizeModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<FontSizeModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const FONT_SAMPLE_MAX_SIZE = 80

    const {isFontSizeModalOpen, closeFontSizeModal, fontSize, setFontSize, setFocus,} = useStore()
    const {surroundWith} = textOperationFunctions(editorRef)

    const settingFontSize = () => {
        const arg = fontSize !== null ? fontSize : ''
        surroundWith(`&size(${arg}){`, '};')
        closeFontSizeModal()
        setFocus(true)
    }

    return <StyledReactModal className="edit-assistant-modal font-size-modal" isOpen={isFontSizeModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true}
                             onRequestClose={() => closeFontSizeModal()}>
        <h3 className="modal-title">文字サイズの設定</h3>
        <div className="modal-body">
            <input type="number" min={1} value={fontSize || ""} onChange={(e) => setFontSize(Number(e.currentTarget.value))} />px
            <div className="font-size-preview">
                <span style={{fontSize: fontSize ? Math.min(fontSize, FONT_SAMPLE_MAX_SIZE) + 'px' : 'inherit'}}>サンプル文字列</span>
            </div>
            <div className="buttons">
                <button className="ok-button" type="button" onClick={settingFontSize} disabled={fontSize === null}>文字サイズを設定</button>
                <button type="button" onClick={() => closeFontSizeModal()}>キャンセル</button>
            </div>
        </div>
    </StyledReactModal>
}
