import React from 'react'
import {createRoot} from 'react-dom/client'
import ArticleForm from "./component/ArticleForm"

(function ($) {
    $.fn.extend({
        "articleForm": function (this: JQuery, options: Record<string, any>) {
            this.each((i, elm) => {
                const $elm = $(elm)
                const params = collectParams($elm, options)
                createRoot(elm).render(React.createElement(ArticleForm, {
                    ...params,
                    securityCheck: {
                        // ダミー (HTML で設定せずコンポーネントで固定実装なので)
                        targetPage: $elm.data('page'),
                        preCheckSms: false,
                        checkSms: true,
                        interruptType: $elm.data('interrupt-type'),
                        unfreezeUrl: $elm.data('unfreeze-url'),
                    },
                }))
            })
        }
    })

    function collectParams(elm: JQuery, options: Record<string, any>) {
        return $.extend({
            formActionPath: elm.data('form-action-path'),
            page: elm.data('page'),
            articleNo: elm.data('article-no'),
            digest: elm.data('digest'),
        }, options)
    }
})(jQuery)
