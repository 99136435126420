import {OperableTextFormComponentType, textOperationFunctions} from '@shared/util/form-text-ops'
import StyledReactModal from '@shared/util/StyledReactModal'
import {RefObject} from 'react'
import {TwitterPicker} from 'react-color'
import {StoreApi, UseBoundStore} from 'zustand'
import {COLORS_MAP, CommonModalState} from './common'

export interface ColorModalState {
    isColorModalOpen: boolean
    characterColor: string
    backgroundColor: string
    openColorModal(): void
    closeColorModal(): void
    setCharacterColor(color: string): void
    setBackgroundColor(color: string): void
    clearColor(): void
}

export function createColorModalState(
    set: StoreApi<ColorModalState>['setState'],
    get: StoreApi<ColorModalState>['getState']
): ColorModalState {
    return {
        isColorModalOpen: false,
        characterColor: '',
        backgroundColor: '',
        openColorModal() {
            set({isColorModalOpen: true})
        },
        closeColorModal() {
            set({isColorModalOpen: false})
            get().clearColor()
        },
        setCharacterColor(color) {
            set({characterColor: color});
        },
        setBackgroundColor(color) {
            set({backgroundColor: color});
        },
        clearColor() {
            set({
                characterColor: '',
                backgroundColor: '',
            })
        },
    }
}

export function ColorModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<ColorModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isColorModalOpen,
        characterColor,
        backgroundColor,
        closeColorModal,
        setCharacterColor,
        setBackgroundColor,
        setFocus,
    } = useStore()

    const {surroundWith} = textOperationFunctions(editorRef)

    const styles = {
        default: {
            hash: {
                display: 'none',
            },
        },
    }

    const settingColor = () => {
        const argChar = COLORS_MAP.hasOwnProperty(characterColor) ? COLORS_MAP[characterColor] : characterColor
        const background = COLORS_MAP.hasOwnProperty(backgroundColor) ? COLORS_MAP[backgroundColor] : backgroundColor
        const argBg = background ? ',' + background : ''
        const beforeText = '&color(' + argChar + argBg + '){'
        const afterText = '};'
        surroundWith(beforeText, afterText)
        closeColorModal()
        setFocus(true)
    }

    return <StyledReactModal className="edit-assistant-modal color-modal" isOpen={isColorModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true}
                             onRequestClose={() => closeColorModal()}>
        <div className="color-preview" style={{backgroundColor: backgroundColor}}>
            <p className="preview-character" style={{color: characterColor}}>文字色</p>
        </div>
        <div className="color-picker">
            <div className="character-color">
                <p>文字色</p>
                <TwitterPicker width="100%" color={characterColor} colors={Object.keys(COLORS_MAP)} styles={styles}
                               onChangeComplete={(color) => setCharacterColor(color.hex)}/>
            </div>
            <div className="background-color">
                <p>背景色</p>
                <TwitterPicker width="100%" color={backgroundColor} colors={Object.keys(COLORS_MAP)} styles={styles}
                               onChangeComplete={(color) => setBackgroundColor(color.hex)}/>
            </div>
        </div>
        <div className="buttons">
            <button className="ok-button" type="button" onClick={settingColor}>色を設定</button>
            <button type="button" onClick={() => closeColorModal()}>キャンセル</button>
        </div>
    </StyledReactModal>
}
