import {RefObject} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'
import StyledReactModal from '@shared/util/StyledReactModal'

export interface EmbedModalState {
    isEmbedModalOpen: boolean
    service: string
    embedUrl: string
    embedWidth: number
    openEmbedModal(service: string): void
    closeEmbedModal(): void
    setEmbedUrl(url: string): void
    setEmbedWidth(width: number): void
    clearEmbedModal(): void
}

export function createEmbedModalState(
    set: StoreApi<EmbedModalState>['setState'],
    get: StoreApi<EmbedModalState>['getState']
): EmbedModalState {
    return {
        isEmbedModalOpen: false,
        service: '',
        embedUrl: '',
        embedWidth: null,
        openEmbedModal(service) {
            set({
                isEmbedModalOpen: true,
                service: service,
            })
        },
        closeEmbedModal() {
            set({isEmbedModalOpen: false})
            get().clearEmbedModal()
        },
        setEmbedUrl(url) {
            set({embedUrl: url})
        },
        setEmbedWidth(width) {
            set({embedWidth: width})
        },
        clearEmbedModal() {
            set({
                service: '',
                embedUrl: '',
                embedWidth: null,
            })
        }
    }
}

export function EmbedModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<EmbedModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isEmbedModalOpen, closeEmbedModal,
        service, embedUrl, setEmbedUrl, embedWidth, setEmbedWidth,
        setFocus
    } = useStore()
    const {insertAtCursor} = textOperationFunctions(editorRef)

    const settingEmbed = () => {
        const width = embedWidth ? ',' + embedWidth : ''
        insertAtCursor(`#embed(${embedUrl + width})`)
        closeEmbedModal()
        setFocus(true)
    }

    return <StyledReactModal className="edit-assistant-modal embed-modal" isOpen={isEmbedModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true}
                             onRequestClose={() => closeEmbedModal()}>
        <h3 className="modal-title">{service}埋め込みの設定</h3>
        <div className="modal-body">
            <label>
                <span>Url</span>
                <input type="url" value={embedUrl} onChange={e => setEmbedUrl(e.currentTarget.value)}/>
            </label>
            <br/>
            <label>
                <span>横幅</span>
                <input type="number" min={1} value={embedWidth || ""} onChange={e => setEmbedWidth(Number(e.currentTarget.value))}/>
            </label>
            <div className="buttons">
                <button className="ok-button" type="button" onClick={settingEmbed} disabled={embedUrl.length === 0}>
                    OK
                </button>
                <button type="button" onClick={() => closeEmbedModal()}>キャンセル</button>
            </div>
        </div>
    </StyledReactModal>
}
