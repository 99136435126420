import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EditAssistant} from '@shared/editAssistant'

import withSecurityCheck, {CheckAndActionProps} from "@shared/security/SecurityCheck"
import SecurityCheckForm from "@shared/security/SecurityCheckForm"
import {OperableTextFormComponentType} from '@shared/util/form-text-ops'
import classNames from 'classnames'
import {useRef, useState} from 'react'
import {createTextFormStore} from './_text-form-common'
import AutoResizeTextarea from './AutoResizeTextarea'

interface CommentFormProps {
    page: string
    formActionPath: string
    commentNo: number
    noDate: '1' | '0'
    noName: '1' | '0'
    above: '1' | '0'
    capacityWarning: string
}

const CommentForm = withSecurityCheck(_CommentForm)
export default CommentForm

function _CommentForm({
    formActionPath, commentNo, noDate,
    noName, above, capacityWarning,
    onAction, isCheckComplete,
}: CommentFormProps & CheckAndActionProps) {
    const COMMENT_SIZE_NAME = 12
    const COMMENT_SIZE_MSG = 58

    const editorRef = useRef<OperableTextFormComponentType>()

    const [name, setName] = useState("")

    const useStore = createTextFormStore()
    const {editData} = useStore()

    const [isEditAssistantShown, setIsEditAssistantShown] = useState(false)

    const handleOnKeyDown = (e: React.KeyboardEvent) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            e.preventDefault()
        }
    }

    return <>
        <br/>
        <SecurityCheckForm className="comment-main-form" onSubmit={(event) => {
            onAction(event, name + "\n\n" + editData)
        }} isCheckComplete={isCheckComplete} action={formActionPath} method="post">
            <div className={classNames('commentform', {
                'no-edit-assistant': !isEditAssistantShown,
                'has-capacity-warning': capacityWarning !== "",
            })}>
                {/* comment.inc.php のソースを見る限り $refpage は絶対空文字になる意味のないパラメーター */}
                {/*<input type="hidden" name="refpage" value="$refpage"/>*/}
                <input type="hidden" name="comment_no" value={commentNo}/>
                <input type="hidden" name="nodate" value={noDate}/>
                <input type="hidden" name="above" value={above}/>
                {!noName &&
                    <input type="text" name="name" size={COMMENT_SIZE_NAME} placeholder="お名前"
                           value={name} onChange={(e) => setName(e.currentTarget.value)}
                           onKeyDown={handleOnKeyDown}/>
                }
                <AutoResizeTextarea useStore={useStore} name="msg" id={'_p_comment_comment_' + commentNo}
                                    autoComplete="off" placeholder="コメント"
                                    cols={noName ? COMMENT_SIZE_NAME + COMMENT_SIZE_MSG : COMMENT_SIZE_MSG}
                                    ref={editorRef} onKeyDown={handleOnKeyDown}
                                    onFocus={() => setIsEditAssistantShown(true)}
                />
                <input type="submit" name="comment" value="挿入"/>
            </div>
        </SecurityCheckForm>
        <div className={classNames('edit-assistant-area', {
            shown: isEditAssistantShown,
            hide: !isEditAssistantShown,
        })}>
            {capacityWarning !== "" && <div className="comment-capacity-warning">{capacityWarning}</div>}
            <div className="controls">
                <EditAssistant inlineOnly={true} editorRef={editorRef}/>
                <FontAwesomeIcon className="hide-edit-assistant" icon={['fas', 'angle-up']} size="lg"
                                 onClick={() => setIsEditAssistantShown(false)}/>
            </div>
        </div>
    </>
}
