require('./css/_index.css');

window.Compressor = require('compressorjs/dist/compressor');

// react-modal
const ReactModal = require('react-modal');
ReactModal.setAppElement('#contents');

require('./js/jquery.uploader');

require('./js/jquery.comment-form');
require('./js/jquery.pcomment-form');
require('./js/jquery.article-form');

import './js/fonts'
