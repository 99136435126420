import {RefObject} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'
import StyledReactModal from '@shared/util/StyledReactModal'

export interface LinkModalState {
    isLinkModalOpen: boolean
    linkTarget: string
    openLinkModal(): void
    closeLinkModal(): void
    setLinkTarget(linkTarget: string): void
    clearLinkModal(): void
}

export function createLinkModalState(
    set: StoreApi<LinkModalState>['setState'],
    get: StoreApi<LinkModalState>['getState']
): LinkModalState {
    return {
        isLinkModalOpen: false,
        linkTarget: '',
        openLinkModal() {
            set({isLinkModalOpen: true})
        },
        closeLinkModal() {
            set({isLinkModalOpen: false})
            get().clearLinkModal()
        },
        setLinkTarget(linkTarget) {
            set({linkTarget: linkTarget})
        },
        clearLinkModal() {
            set({linkTarget: ''})
        }
    }
}

export function LinkModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<LinkModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isLinkModalOpen, closeLinkModal,
        linkTarget, setLinkTarget, setFocus
    } = useStore()
    const {surroundWith, getSelectedText} = textOperationFunctions(editorRef)

    const insertLink = () => {
        const selectedText = getSelectedText()
        if (selectedText.length > 0 && selectedText === linkTarget) {
            surroundWith('[[', ']]')
        } else {
            surroundWith('[[', `>${linkTarget}]]`)
        }

        closeLinkModal()
        setFocus(true)
    }

    return <StyledReactModal className="edit-assistant-modal link-modal" isOpen={isLinkModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true}
                             onRequestClose={() => closeLinkModal()}>
        <h3 className="modal-title">リンクの作成</h3>
        <div className="modal-body">
            <div>
                <label>
                    <span>リンク先</span>
                    <input type="text" value={linkTarget} onChange={e => setLinkTarget(e.currentTarget.value)}/>
                </label>
            </div>
        </div>
        <div className="buttons">
            <button className="ok-button" type="button" onClick={insertLink}>OK</button>
            <button type="button" onClick={() => closeLinkModal()}>キャンセル</button>
        </div>
    </StyledReactModal>
}
