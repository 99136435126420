import React from 'react'
import {createRoot} from 'react-dom/client'
import Uploader, {UploaderProps} from "./component/Uploader"

(function ($) {

    $.fn.extend({
        /**
         * フォームで送信する画像ファイルがサイズ制限を超過している場合に自動的に縮小してから送信する。
         * @param options
         */
        'uploader': function (this: JQuery, options) {
            const defaultOptions = {
                actionUrl: "",
                page: "",
                hiddenArgs: [],
                attachFileInputName: 'attach_file',
                maxFileSizeInputName: 'max_file_size',
                compressorQuality: 0.8,
                compressorMaxWidth: 2048,
                compressorMaxHeight: 2048,
                maxFileSize: 512 * 1024,
                adminOnly: false,
                passwordRequired: false,
                submitName: null,
            }

            return this.each(function () {
                let self = $(this)
                let props: UploaderProps = $.extend(defaultOptions, options, {
                    actionUrl: self.data('action-url'),
                    page: self.data('page'),
                })
                createRoot(this).render(React.createElement(Uploader, {
                    ...props,
                    securityCheck: {
                        targetPage: self.data('page'),
                        preCheckSms: false,
                        checkSms: true,
                        interruptType: 'none',
                        unfreezeUrl: '',
                    }
                }))
            })
        },
    })

})(jQuery)
