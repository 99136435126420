import {RefObject, SyntheticEvent} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'
import StyledReactModal from '@shared/util/StyledReactModal'

type TwitterType = 'tweet' | 'timeline'

export interface TwitterModalState {
    isTwitterModalOpen: boolean
    twitterType: TwitterType
    twitterID: string
    twitterWidth: number | null
    twitterHeight: number | null
    openTwitterModal(): void
    closeTwitterModal(): void
    setTwitterType(type: TwitterType): void
    setTwitterID(id: string): void
    setTwitterWidth(width: number | null): void
    setTwitterHeight(height: number | null): void
    clearTwitterModal(): void
}

export function createTwitterModalState(
    set: StoreApi<TwitterModalState>['setState'],
    get: StoreApi<TwitterModalState>['getState']
): TwitterModalState {
    return {
        isTwitterModalOpen: false,
        twitterType: 'tweet',
        twitterID: '',
        twitterWidth: null,
        twitterHeight: null,
        openTwitterModal() {
            set({isTwitterModalOpen: true})
        },
        closeTwitterModal() {
            set({isTwitterModalOpen: false})
            get().clearTwitterModal()
        },
        setTwitterType(type: TwitterType) {
            set({twitterType: type})
        },
        setTwitterID(id: string) {
            set({twitterID: id})
        },
        setTwitterWidth(width: number | null) {
            set({twitterWidth: width})
        },
        setTwitterHeight(height: number | null) {
            set({twitterHeight: height})
        },
        clearTwitterModal() {
            set({
                twitterType: 'tweet',
                twitterID: '',
                twitterWidth: null,
                twitterHeight: null,
            })
        }
    }
}

export function TwitterModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<TwitterModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isTwitterModalOpen, closeTwitterModal,
        twitterType, setTwitterType, twitterID, setTwitterID,
        twitterWidth, setTwitterWidth, twitterHeight, setTwitterHeight,
        setFocus
    } = useStore()

    const {insertAtCursor} = textOperationFunctions(editorRef)

    const settingTwitter = () => {
        if (twitterType === 'tweet') {
            insertAtCursor(`#embed(${twitterID})`)
        } else {
            const width = twitterWidth ? ',w=' + twitterWidth : ''
            const height = twitterHeight ? ',h=' + twitterHeight : ''
            insertAtCursor(`#twitter_timeline(${'@=' + twitterID + width + height})`)
        }

        closeTwitterModal()
        setFocus(true)
    }

    const onTypeChanged = (e: SyntheticEvent<HTMLInputElement>) => {
        setTwitterType(e.currentTarget.value as TwitterType);
    }

    const labelColor = twitterType === 'tweet' ? '#777': 'inherit'

    return <StyledReactModal className="edit-assistant-modal twitter-modal" isOpen={isTwitterModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true}
                             onRequestClose={() => closeTwitterModal()}>
        <h3 className="modal-title">Twitter埋め込みの設定</h3>
        <div className="modal-body">
            <div className="twitter-type-select">
                <label>
                    {/* @ts-ignore */}
                    <input type="radio"  value="tweet" checked={twitterType === 'tweet'}
                           onChange={onTypeChanged}/>ツイート
                </label>
                <label>
                    {/* @ts-ignore */}
                    <input type="radio"  value="timeline" checked={twitterType === 'timeline'}
                           onChange={onTypeChanged}/>タイムライン
                </label>
            </div>
            <div className="twitter-params">
                <label>
                    <span>{twitterType === 'tweet' ? 'URL' : '@'}</span>
                    <input type="text" value={twitterID} onChange={e => setTwitterID(e.currentTarget.value)}/>
                </label>
                <label>
                    <span style={{color: labelColor}}>横幅</span>
                    <input type="number" min={1} value={twitterWidth || ""} disabled={twitterType === 'tweet'}
                           onChange={e => setTwitterWidth(Number(e.currentTarget.value))}/>
                </label>
                <label>
                    <span style={{color: labelColor}}>高さ</span>
                    <input type="number" min={1} value={twitterHeight || ""} disabled={twitterType === 'tweet'}
                           onChange={e => setTwitterHeight(Number(e.currentTarget.value))}/>
                </label>
            </div>
            <div className="buttons">
                <button className="ok-button" type="button" onClick={settingTwitter} disabled={twitterID.length === 0}>
                    OK
                </button>
                <button type="button" onClick={() => closeTwitterModal()}>キャンセル</button>
            </div>
        </div>
    </StyledReactModal>
}
