import {ChangeEvent, RefObject} from 'react'
import Spreadsheet, {
    CellBase, DataEditor, DataEditorProps,
    DataViewer, DataViewerProps,
    Matrix,
} from 'react-spreadsheet'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, SelectionRangeElement, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'
import StyledReactModal, {StyledReactModalDefaultStyles} from '@shared/util/StyledReactModal'

export interface TableModalState {
    isTableModalOpen: boolean
    tableRowCount: number
    tableColumnCount: number
    tableData: Matrix<CellBase>
    rowTypes: (TableRowModifier|null)[]
    commentRows: Set<number>,
    tableTextSelection: SelectionRangeElement|null
    openTableModalEditMode(cursorPos: number, editData: string): void
    openTableModalInsertMode(): void
    closeTableModal(): void
    setTableRowCount(num: number): void
    setTableColumnCount(num: number): void
    setTableData(data: Matrix<CellBase>): void
    setRowTypesAtIndex(index: number, type: TableRowModifier|null): void
    toggleCommentRow(rowIndex: number): void
    clearTableModal(): void
}

type TableRowModifier = 'h'|'f'|'c'

const MODIFIERS: TableRowModifier[]  = ['h', 'f', 'c'] as const

const CONTROL_CELL_NUM = 2

const INIT_TABLE_ROW = 4
const INIT_TABLE_COLUMN = 4
const INIT_TABLE_DATA: Matrix<CellBase> = new Array(INIT_TABLE_ROW)
    .fill((new Array(INIT_TABLE_COLUMN + CONTROL_CELL_NUM)).fill({value: ''}))
const INIT_ROW_TYPES: (TableRowModifier|null)[] = new Array(INIT_TABLE_ROW).fill(null)

function copyTableRows(base: Matrix<CellBase>): Matrix<CellBase> {
    return base.map(line => [...line])
}

export function createTableModalState(
    set: StoreApi<TableModalState>['setState'],
    get: StoreApi<TableModalState>['getState'],
): TableModalState {
    return {
        isTableModalOpen: false,
        tableRowCount: INIT_TABLE_ROW,
        tableColumnCount: INIT_TABLE_COLUMN,
        tableData: INIT_TABLE_DATA,
        rowTypes: INIT_ROW_TYPES,
        commentRows: new Set<number>(),
        tableTextSelection: null,
        openTableModalEditMode(cursorPos, editData) {
            const currentRow = getCurrentRow(cursorPos, editData)
            let startPos = currentRow.rowStart
            let endPos = currentRow.rowEnd
            let selectedText = currentRow.rowText

            if (isTableLine(selectedText)) {
                while (startPos > 0) {
                    const upRow = getCurrentRow(startPos - 1, editData)
                    if (!isTableLine(upRow.rowText)) {
                        break
                    }
                    startPos = upRow.rowStart
                }

                while (endPos < editData.length) {
                    const downRow = getCurrentRow(endPos + 1, editData)
                    if (!isTableLine(downRow.rowText)) {
                        break
                    }
                    endPos = downRow.rowEnd
                }

                selectedText = editData.substring(startPos, endPos)
            }

            const tableTextSelection = {
                start: startPos,
                end: endPos,
            }

            const rows = selectedText.split('\n')
            if (selectedText.length > 0) {
                const tableData: Matrix<CellBase> = []
                const commentRows = new Set<number>()
                const rowTypes = []

                rows.forEach((row, index) => {
                    const commentMatch = /^\/\/\s*(.*)$/.exec(row)
                    if (commentMatch) {
                        row = commentMatch[1].trim()
                        commentRows.add(index)
                    }
                    const result = /^\|(.*)\|([hHfFcC]?)$/.exec(row)
                    if (result) {
                        // 先頭に行タイプ変更のための空セル追加
                        const controlCells = Array(CONTROL_CELL_NUM).fill('')
                        const cells = controlCells.concat(result[1].split('|'))

                        if (result[2]) {
                            rowTypes[index] = result[2].toLowerCase() as TableRowModifier
                        } else {
                            rowTypes[index] = null
                        }

                        tableData.push(cells.map(cell => ({value: cell})))
                    } else {
                        // 先頭に行タイプ変更のための空セル追加
                        tableData.push([{value: ''}, {value: row}])
                    }
                })

                const actualMaxColumnCount = Math.max(...tableData.map(row => row.length))
                // 一番上の行の長さでセル数が決まってしまうので、列数を揃える
                tableData.forEach(row => {
                    if (row.length < actualMaxColumnCount) {
                        const diff = actualMaxColumnCount - row.length
                        for (let i = 0; i < diff; i++) {
                            row.push({value: ''})
                        }
                    }
                })

                set({
                    tableRowCount: tableData.length,
                    tableColumnCount: actualMaxColumnCount - CONTROL_CELL_NUM,
                    tableData: tableData,
                    rowTypes: rowTypes,
                    commentRows: commentRows,
                    tableTextSelection: tableTextSelection,
                })
            }

            set({isTableModalOpen: true})

            function isTableLine(text: string) {
                const commentMatch = /^\/\/\s*(.*)$/.exec(text)
                if (commentMatch) {
                    text = commentMatch[1]
                }

                return /^\|(.*)\|([hHfFcC]?)$/.test(text)
            }

            function getCurrentRow(currentCursorPos: number, editData: string) {
                const rowStart = findRowStart(currentCursorPos, editData)
                const rowEnd = findRowEnd(currentCursorPos, editData)
                return {
                    rowStart: rowStart,
                    rowEnd: rowEnd,
                    rowText: editData.substring(rowStart, rowEnd)
                }
            }

            function findRowStart(start: number, editData: string): number {
                let lineStart = start
                while (lineStart > 0) {
                    if (editData[lineStart - 1] === '\n') {
                        break
                    }
                    lineStart--
                }
                return lineStart
            }

            function findRowEnd(end: number, editData: string): number {
                let lineEnd = end
                while (lineEnd < editData.length) {
                    if (editData[lineEnd] === '\n') {
                        break
                    }
                    lineEnd++
                }
                return lineEnd
            }
        },
        openTableModalInsertMode() {
            set({isTableModalOpen: true})
        },
        closeTableModal() {
            set({isTableModalOpen: false})
            get().clearTableModal()
        },
        setTableRowCount(num) {
            const prevTableRow = get().tableRowCount
            const tableData = copyTableRows(get().tableData)
            const rowTypes = [...get().rowTypes]
            const commentRows = new Set(get().commentRows)

            if (num < prevTableRow) {
                const diff = prevTableRow - num
                tableData.splice(-diff, diff)
                rowTypes.splice(-diff, diff)
                for (let i = 0; i < diff; i++) {
                    commentRows.delete(prevTableRow - i - 1)
                }
            } else if (num > prevTableRow) {
                const diff = num - prevTableRow
                // 行タイプ用の列を考慮
                const emptyRow = (new Array(get().tableColumnCount + CONTROL_CELL_NUM)).fill({value: ''})
                for (let i = 0; i < diff; i++) {
                    tableData.push(emptyRow)
                    rowTypes.push(null)
                }
            }

            set({
                tableRowCount: num,
                tableData: tableData,
                rowTypes: rowTypes,
                commentRows: commentRows,
            })
        },
        setTableColumnCount(num) {
            const prevTableColumn = get().tableColumnCount
            const tableData = copyTableRows(get().tableData)

            if (num < prevTableColumn) {
                const diff = prevTableColumn - num
                tableData.forEach(row => {
                    if (row.length > num) {
                        row.splice(-diff, diff)
                    }
                })
            } else if (num > prevTableColumn) {
                const diff = num - prevTableColumn
                const emptyCell = {value: ''}
                tableData.forEach(row => {
                    for (let i = 0; i < diff; i++) {
                        row.push(emptyCell)
                    }
                })
            }
            set({
                tableColumnCount: num,
                tableData: tableData,
            })
        },
        setTableData(data) {
            set({tableData: data})
        },
        setRowTypesAtIndex(index, type) {
            const rowTypes = [...get().rowTypes]
            rowTypes[index] = type
            set({rowTypes: rowTypes})
        },
        toggleCommentRow(rowIndex) {
            const commentRows = new Set(get().commentRows)
            if (commentRows.has(rowIndex)) {
                commentRows.delete(rowIndex)
            } else {
                commentRows.add(rowIndex)
            }
            set({commentRows: commentRows})
        },
        clearTableModal() {
            set({
                tableRowCount: INIT_TABLE_ROW,
                tableColumnCount: INIT_TABLE_COLUMN,
                tableData: INIT_TABLE_DATA,
                rowTypes: INIT_ROW_TYPES,
                commentRows: new Set<number>(),
                tableTextSelection: null,
            })
        },
    }
}

export function TableModal({useStore, editorRef}: {
    useStore: UseBoundStore<StoreApi<TableModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        isTableModalOpen, closeTableModal,
        tableRowCount, setTableRowCount, tableColumnCount, setTableColumnCount,
        setFocus, tableData, setTableData,
        rowTypes, setRowTypesAtIndex, commentRows, toggleCommentRow,
        tableTextSelection
    } = useStore()
    const {insertAtCursor, selectionReplace} = textOperationFunctions(editorRef)
    const insertTable = () => {
        const table = tableData.map(
            (row, i) => {
                const modifier = rowTypes[i] || ''
                const rowStr = '|' + row
                    // 先頭の行タイプ変更用セルは無視
                    .filter((_, index) => index >= CONTROL_CELL_NUM)
                    .map((cell, index) => cell.value)
                    .join('|')
                    + '|' + modifier
                if (commentRows.has(i)) {
                    return '// ' + rowStr
                }
                return rowStr
            }).join('\n')

        if (tableTextSelection !== null) {
            editorRef.current?.setSelections([tableTextSelection])
        }

        if (tableTextSelection) {
            selectionReplace(s => table, [tableTextSelection])
        } else {
            insertAtCursor(table)
        }

        closeTableModal()
        setFocus(true)
    }

    function isMatrixEqual(data1: Matrix<CellBase>, data2: Matrix<CellBase>): boolean {
        if (data1.length !== data2.length) {
            return false;
        }
        for (let i = 0; i < data1.length; i++) {
            if (data1[i].length !== data2[i].length) {
                return false;
            }
            for (let j = 0; j < data1[i].length; j++) {
                if (data1[i][j].value !== data2[i][j].value) {
                    return false;
                }
            }
        }
        return true;
    }

    const ToggleCommentButton = ({row}: {row: number}) => {
        const className = 'comment-row-toggle' +  (commentRows.has(row) ? ' toggle-on' : ' toggle-off')
        return <div className={className} onClick={e => toggleCommentRow(row)}>//</div>
    }

    const SwitchRowTypeForm = ({row}: {row: number}) => {
        const NO_MODIFIER = '---'
        const modifierLabels = {
            h: 'ヘッダー',
            f: 'フッター',
            c: '書式指定',
        }

        const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
            const value = e.currentTarget.value
            const modifier = value === NO_MODIFIER ? null : value as TableRowModifier
            setRowTypesAtIndex(row, modifier)
        }

        const selectValue = rowTypes[row] || NO_MODIFIER
        const selectClassName = 'row-modifier-select '
            + (selectValue === NO_MODIFIER ? '' : 'select-' + selectValue)

        return <select onChange={onSelectChange} className={selectClassName} value={selectValue}>
                {
                    [NO_MODIFIER, ...MODIFIERS].map(modifier => <option value={modifier} key={modifier}>
                        {modifierLabels[modifier] || NO_MODIFIER}
                    </option>)
                }
            </select>
    }

    const SwitchRowTypeDataViewer = (props: DataViewerProps) => {
        const {row, column} = props
        if (column === 0) {
            return <ToggleCommentButton row={row}/>
        } else if (column === 1) {
            return <SwitchRowTypeForm row={row}/>
        }

        return <DataViewer {...props}/>
    }

    const SwitchRowTypeDataEditor = (props: DataEditorProps) => {
        const {row, column} = props
        if (column === 0) {
            return <ToggleCommentButton row={row}/>
        } else if (column === 1) {
            return <SwitchRowTypeForm row={row}/>
        }

        return <DataEditor {...props}/>
    }

    const onChangeSpreadsheet = (data: Matrix<CellBase>) => {
        if (!isMatrixEqual(data, tableData)) {
            setTableData(data)
        }
    }

    const styles = {
        ...StyledReactModalDefaultStyles,
        content: {
            ...StyledReactModalDefaultStyles.content,
            maxWidth: 'none'
        }
    }

    return <StyledReactModal className="edit-assistant-modal table-modal" isOpen={isTableModalOpen}
                             shouldReturnFocusAfterClose={false}
                             shouldCloseOnOverlayClick={true} style={styles}
                             onRequestClose={() => closeTableModal()}>
        <h3 className="modal-title">テーブル</h3>
        <div className="modal-body">
            <div className="spreadsheet-control">
                <label>
                    <span className="input-description">行</span>
                    <input type="number" value={tableRowCount} min={1}
                           onChange={e => {
                               setTableRowCount(Number(e.currentTarget.value))
                           }
                           }/>
                </label>
                <label>
                    <span className="input-description">列</span>
                    <input type="number" value={tableColumnCount} min={1}
                           onChange={e => setTableColumnCount(Number(e.currentTarget.value))}/>
                </label>
                <br/>
            </div>
            <div className="spreadsheet-wrapper">
                <Spreadsheet data={tableData} onChange={onChangeSpreadsheet} hideRowIndicators={true}
                             hideColumnIndicators={true} DataViewer={SwitchRowTypeDataViewer} DataEditor={SwitchRowTypeDataEditor}/>
            </div>
            <div className="buttons">
                <button className="ok-button" type="button" onClick={insertTable}>
                    {tableTextSelection === null ? '挿入' : '更新'}
                </button>
                <button type="button" onClick={() => closeTableModal()}>キャンセル</button>
            </div>
        </div>
    </StyledReactModal>
}
