import { library,dom } from '@fortawesome/fontawesome-svg-core'
import {
    faCheck as faCheckProLight,
} from '@fortawesome/pro-light-svg-icons'
import {
    faTextHeight as faTextHeightFreeSolid,
    faBold as faBoldFreeSolid,
    faItalic as faItalicFreeSolid,
    faStrikethrough as faStrikethroughFreeSolid,
    faUnderline as faUnderlineFreeSolid,
    faHeading as faHeadingFreeSolid,
    faListUl as faListUlFreeSolid,
    faListOl as faListOlFreeSolid,
    faAlignLeft as faAlignLeft,
    faAlignCenter as faAlignCenter,
    faAlignRight as faAlignRightFreeSolid,
    faQuoteLeft as faQuoteLeftFreeSolid,
    faTable as faTableFreeSolid,
    // faPaperclip as faPaperclipFreeSolid,
    faWindowClose as faWindowCloseFreeSolid,
    faUndo as faUndoFreeSolid,
    faRedo as faRedoFreeSolid,
    faCut as faCutFreeSolid,
    faSearch as faSearchFreeSolid,
    faExternalLink as faExternalLinkFreeSolid,
    faLock as faLockFreeSolid,
    faSignIn as faSignInFreeSolid,
    faSignOut as faSignOutFreeSolid,
    faHandPointRight as faHandPointRightFreeSolid,
    faSync as faSyncFreeSolid,
    faRectangleAd as faRectangleAdFreeSolid,
    faHouseFire as faHouseFireFreeSolid,
    faEyedropper as faEyedropperFreeSolid,
    faAngleUp as faAngleUpFreeSolid,
    faAngleRight as faAngleRightFreeSolid,
    faAngleLeft as faAngleLeftFreeSolid,
} from '@fortawesome/free-solid-svg-icons'
import {
    faHorizontalRule as faHorizontalRuleProSolid,
    faHatWizard as faHatWizardProSolid,
    faMessageExclamation as faMessageExclamationProSolid,
    faBlockQuestion as faBlockQuestionProSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faFaceLaugh as faFaceLaughFreeRegular,
} from '@fortawesome/free-regular-svg-icons'
import {
    faYoutube as faYoutubeFreeBrands,
    faTwitter as faTwitterFreeBrands,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faTextHeightFreeSolid,
    faBoldFreeSolid,
    faItalicFreeSolid,
    faStrikethroughFreeSolid,
    faUnderlineFreeSolid,
    faHeadingFreeSolid,
    faListUlFreeSolid,
    faListOlFreeSolid,
    faAlignLeft,
    faAlignCenter,
    faAlignRightFreeSolid,
    faQuoteLeftFreeSolid,
    faTableFreeSolid,
    faHorizontalRuleProSolid,
    // faPaperclipFreeSolid,
    faYoutubeFreeBrands,
    faTwitterFreeBrands,
    faFaceLaughFreeRegular,
    faWindowCloseFreeSolid,
    faUndoFreeSolid,
    faRedoFreeSolid,
    faCutFreeSolid,

    faSearchFreeSolid,

    faExternalLinkFreeSolid,
    faLockFreeSolid,
    faSignInFreeSolid,
    faSignOutFreeSolid,
    faHandPointRightFreeSolid,
    faSyncFreeSolid,

    faCheckProLight,

    faRectangleAdFreeSolid,
    faHatWizardProSolid,
    faHouseFireFreeSolid,
    faMessageExclamationProSolid,
    faEyedropperFreeSolid,
    faBlockQuestionProSolid,
    faAngleUpFreeSolid,
    faAngleRightFreeSolid,
    faAngleLeftFreeSolid
)

dom.watch()
