// react-color が色名に対応してないので
// wikiソースの可読性のため色名に変換してやる
import {StoreApi} from 'zustand'

export const COLORS_MAP = {
    '#000000': 'black',
    '#808080': 'gray',
    '#c0c0c0': 'silver',
    '#ffffff': 'white',
    '#0000ff': 'blue',
    '#000080': 'navy',
    '#008080': 'teal',
    '#008000': 'green',
    '#00ff00': 'lime',
    '#00ffff': 'aqua',
    '#ffff00': 'yellow',
    '#ff0000': 'red',
    '#ff00ff': 'fuchsia',
    '#808000': 'olive',
    '#800080': 'purple',
    '#800000': 'maroon',
}

export interface CommonModalState {
    focus: boolean
    setFocus(focus: boolean): void
}

export function createCommonModalState(
    set: StoreApi<CommonModalState>['setState'],
    _get: StoreApi<CommonModalState>['getState']
): CommonModalState {
    return {
        focus: false,
        setFocus(focus: boolean) {
            set({focus: focus})
        },
    }
}
