import {ReactNode, RefObject, useEffect, useRef} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {CommonModalState} from './common'

export interface EditAssistantImageSource {
    plus_hint: null
    plus_easy: null
    plus_adv: null
    plus_buttons: null
    plus_clip: null
    plus_colors: null
    plus_ncr: null
    plus_br: null
    face_smile: string
    face_bigsmile: string
    face_huh: string
    face_oh: string
    face_wink: string
    face_sad: string
    face_worried: string
    face_tear: string
    face_tere: string
    face_shock: string
    face_heart: string
    face_star: string
    face_hatena: string
    face_tip: string
}

export interface FaceImageButtonState {
    isFaceImageListOpen: boolean
    openFaceImageList(): void
    closeFaceImageList(): void
}

export function createFaceImageButtonState(
    set: StoreApi<FaceImageButtonState>['setState'],
    _get: StoreApi<FaceImageButtonState>['getState'] // unused
): FaceImageButtonState {
    return {
        isFaceImageListOpen: false,
        openFaceImageList() {
            set({isFaceImageListOpen: true})
        },
        closeFaceImageList() {
            set({isFaceImageListOpen: false})
        }
    }
}

export function FaceImageButton({useStore, editorRef, children}: {
    useStore: UseBoundStore<StoreApi<FaceImageButtonState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
    children: ReactNode
}) {
    const {isFaceImageListOpen, openFaceImageList, closeFaceImageList, setFocus} = useStore()
    const {insertAtCursor} = textOperationFunctions(editorRef)

    const listRef: RefObject<HTMLUListElement> = useRef()
    const buttonRef: RefObject<HTMLButtonElement> = useRef()

    const imgSrc = window.globalParamsContainer.editAssistantImgSrc

    // リストの外をクリックしていたらメニューを閉じるための処理
    const documentClickHandler = e => {
        if (!isFaceImageListOpen || buttonRef.current?.contains(e.target) || listRef.current?.contains(e.target)) {
            e.stopPropagation()
            return
        }
        closeFaceImageList()
    }
    useEffect(() => {
        document.addEventListener('click', documentClickHandler)

        return () => document.removeEventListener('click', documentClickHandler)
    }, [isFaceImageListOpen])

    const handleClickFace = e => {
        insertAtCursor(e.currentTarget.getAttribute('alt'))
        closeFaceImageList()
        setFocus(true)
    }

    return <>
        <button type="button" className="assist-button" ref={buttonRef} onClick={() => openFaceImageList()}>{children}</button>
        {isFaceImageListOpen &&
            <ul className="face-image-list" ref={listRef}>
                <li><img src={imgSrc.face_smile} title="(^^)" alt="(^^)" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_bigsmile} title="(^-^" alt="(^-^" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_huh} title="(^Q^" alt="(^Q^" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_oh} title="(..;" alt="(..;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_wink} title="(^_-" alt="(^_-" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_sad} title="(--;" alt="(--;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_worried} title="(^^;" alt="(^^;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_tear} title="(T-T" alt="(T-T" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_tere} title="(//" alt="(//" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_shock} title="(OO;" alt="(OO;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_heart} title="&amp;heart;" alt="&amp;heart;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_star} title="&amp;star;" alt="&amp;star;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_hatena} title="&amp;hatena;" alt="&amp;hatena;" onClick={handleClickFace}/></li>
                <li><img src={imgSrc.face_tip} title="&amp;tip;" alt="&amp;tip;" onClick={handleClickFace}/></li>
            </ul>
        }
    </>
}
